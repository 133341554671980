<template lang="pug">
div(class='step' :class='classes')
  div(class='step__line')
  div(class='step__dot')
    icon(class='step__icon' :name='icon' :data-tooltip='tooltip')
    div(class='step__title')
      slot
</template>

<script lang="ts">
import { type RegisterStepFn } from './Steps.vue'
import { defineComponent, computed, inject, ref } from 'vue'

const Step = defineComponent({
  props: {
    id: { type: String, required: true },
    icon: { type: String, default: 'check' },
    tooltip: String,
    completedIfActive: Boolean,
  },
  setup(props) {
    const currentIndex = inject('currentIndex', ref(0))
    const index = inject<RegisterStepFn>('register')!(props.id)
    const status = computed(() =>
      index < currentIndex.value || (props.completedIfActive && index === currentIndex.value)
        ? 'checked'
        : index === currentIndex.value
          ? 'active'
          : 'inactive',
    )
    return {
      index,
      status,
      classes: computed(() => ({
        [`step--${status.value}`]: true,
      })),
    }
  },
})
export default Step
</script>

<style lang="stylus">
@import '../../styles/variables.styl'

.step
  color: var(--grey-400)
  display: flex
  &:first-of-type::after
    content: none
  &--checked, &--active
    color: var(--green-400)
  &__line
    flex: 1
    margin: 8px 4px 0
    height: 3px
    ~/--checked &, ~/--active &
      background-color: var(--green-400)
    ~/--inactive &
      background-image: linear-gradient(90deg, var(--grey-200), var(--grey-200) 30%, transparent 30%, transparent 100%)
      background-size: 10px 20px
    ~/:first-child &
      display: none
  &__dot
    position: relative
    display: f
  &__title
    position: absolute
    top: 30px
    width: auto
    min-width: 200px
    text-align: center
    transform: translateX(-50%)
    left: 50%
    line-height: 1.2
    font-size: 14px
    ~/--active &
      font-weight: bold
    @media (max-width: $breakpoint-md-max)
      ~/:last-child &,
      ~/:first-child &
        min-width: auto
        transform: none
      ~/:first-child &
        text-align: left
        left: 0
      ~/:last-child &
        right: 0
        text-align: right
        left: auto
  &__icon
    display: block
    color: white
    width: 20px
    height: 20px
    padding: 2px
    border-radius: 50%
    line-height: 1.25
    text-align: center
    stroke-width: 3px
    ~/--checked &
      background: var(--green-400)
      border: 2px solid var(--green-400)
    ~/--active &
      border: 3px solid var(--green-400)
    ~/--inactive &
      border: 2px solid var(--grey-200)
</style>
