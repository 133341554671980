<template lang="pug">
div(class='steps')
  slot
</template>

<script lang="ts">
import { defineComponent, ref, computed, provide } from 'vue'

export type RegisterStepFn = (id: string) => number
const Steps = defineComponent({
  props: {
    currentActiveId: String,
  },
  setup(props) {
    const steps = ref<string[]>([])
    const stepsAsMap = computed(() => Object.fromEntries(steps.value.map((id, i) => [id, i])))
    const currentIndex = computed(() => stepsAsMap.value[props.currentActiveId!] || 0)
    provide<RegisterStepFn>('register', (id: string) => (steps.value.push(id), stepsAsMap.value[id]))
    provide('currentIndex', currentIndex)
    return {
      currentIndex,
    }
  },
})
export default Steps
</script>
<style lang="stylus">
.steps
  margin: 30px 0 60px
  display: grid
  grid-auto-flow: column
  grid-template-columns: 20px auto
</style>
