<template lang="pug">
div(v-if='inquiry' data-test='inquiry-detail' class='flex flex-1 flex-col')
  //- tour-wrapper(id='/projects/[id]/inquiries/[inquiryId]' v-if='inquiry')
  div(class='bleed' :class='{ "!bg-body": $route.name === "/inquiries/[id]" }')
    div(class='unbleed')
      toolbar(class='mt-2' titleClass='!text-2xl' :wrap='false')
        div(data-test='inquiry-title' :data-inquiry-id='inquiry.id')
          span(class='font-black') {{ $t(isNewInquiry ? 'New Inquiry' : 'Inquiry') }}: !{ ' ' }
            span(class='mr-2 font-light') {{ inquiry.inqTitle || inquiry.id }}
            btn(v-if='isInquiryStatusDrafted && !inquiry.deleted' icon='custom-edit' @click='actions.editTitle(inquiryId)' small :data-tooltip='$t("Edit inquiry title")' faded)
        template(#titleAfter)
          div(class='flex items-center')
            pill(v-if='inquiry.deleted' type='error') {{ $t('deleted') }}
            inquiry-status-pill(v-else-if='isInquiryStatusDeclinedExpiredOrDeleted' :status='inquiry.status' :data-test-inquiry-status='inquiry.status')
        template(#after)
          btn(v-if='$permissions.canSeePurchasingPrices' icon='custom-edit' admin secondary :to='$modals.getLocation("form-inquiry-admin", { id: inquiry.id })')
          avatar-list
            avatar(v-if='inquiry.createdByUser' :username='inquiry.createdByUser.userFullName' :data-tooltip='`<small>${$t("Created By User")}:</small><br> ${inquiry.createdByUser.userFullName}`')
            avatar(
              v-if='inquiry.operatorFullName'
              :username='inquiry.operatorFullName'
              icon='custom-nuucon'
              :data-tooltip='`<small>${$t("Responsible Operator")}:</small><br> ${inquiry.operatorFullName}`'
            )
          dropdown(v-if='isInquiryStatusDrafted && !isNewInquiry') 
            template(#default='{ toggle, visible }')
              btn(v-if='breakpoints.md' secondary icon='plus' :active='visible' data-test='btn-add-product' @click='toggle') {{ $t('Add products') }}
              btn(v-else primary icon='plus' secondary :active='visible' :data-tooltip='$t("Add products")' data-test='btn-add-product' @click='toggle')
            template(#content)
              dropdown-item(
                v-if='inquiry !== null'
                lg
                icon='custom-move-from-project'
                data-test='btn-add-products-from-project'
                :text='$t("Produkte von Projekt hinzufügen")'
                :description='$t("Wähle Produkte aus dem zugehörigen Projekt aus.")'
                @click='openAddProductsFromProjectForm(inquiry.projectId, inquiryId)'
              )

              dropdown-item(
                lg
                data-test='btn-add-product-from-catalog'
                icon='search'
                :text='$t("From platform")'
                :description='$t("Choose from nuucon product catalogue.")'
                @click='openAddProductsForm(inquiry.projectId, inquiryId)'
              )
              //- dropdown-item(
              //-   lg
              //-   @click='openImportProductsForm(inquiry.projectId, inquiryId)'
              //-   icon='custom-import-to-project'
              //-   data-test='btn-import-products'
              //-   :text='$t("Produkte importieren")'
              //-   :description='$t("Produkte von Excel-, CSV- oder OBX-Dateien (Pcon) importieren.")'
              //- )
              dropdown-item(
                lg
                data-test='btn-add-product-from-pcon-catalog'
                icon='custom-rotate-furniture'
                :text='$t("pCon Katalog")'
                :description='$t("Wähle konfigurierbare Produkte aus dem pCon-Katalog aus.")'
                @click='openPconCatalog(inquiry.projectId, inquiryId)'
              )
              dropdown-item(
                lg
                data-test='btn-add-external-product'
                icon='external-link'
                :text='$t("From external source")'
                :description='$t("Choose from any other website.")'
                @click='openAddExternalProductForm(inquiry.projectId, inquiryId)'
              )
          dropdown-options(secondary data-test='btn-inquiry-options')
            dropdown-item(data-test='btn-inquiry-pdf-preview' icon='printer' :text='$t("Print Preview")' @click='actions.showPrintPreview(inquiryId)')
            dropdown-item(v-if='!isNewInquiry' data-test='btn-inquiry-copy' icon='copy' :text='$t("Copy Inquiry")' @click='copyInquiry(inquiryId)')
            dropdown-item(icon='download' data-test='btn-project-export' @click='$modals.open("form-export", { projectId, inquiryId })') {{ $t('Exportieren') }}
            dropdown-item(v-if='!isNewInquiry' data-test='btn-inquiry-remove' icon='trash' danger :disabled='inquiry.deleted' :text='$t("Delete Inquiry")' @click='removeInquiry(inquiryId)')

          slot(name='toolbarRight')

      div(class='mb-4 flex flex-wrap gap-3')
        div(v-if='inquiry.previousVersion' class='text-sm text-text-light')
          span {{ $t('Previous Version') }}: !{ ' ' }
          router-link(:replace='false' class='link-faded' :to='$modals.getLocation("form-inquiry", { projectId: inquiry.projectId, inquiryId: inquiry.previousVersion })') {{ inquiry.previousVersion }}
        div(v-if='!isNewInquiry' class='link-tooltip flex items-center gap-1 text-sm')
          icon(name='calendar')
          date-tooltip(v-slot='{ text }' :dateAsString='inquiry.createdAt' :prefix='$t("Creation date")') {{ $t('created {0}', '', [text]) }}
        router-link(v-if='!isNewInquiry' :data-tooltip='$t("Related project")' class='link-faded flex items-center gap-1 text-sm' :to='{ name: "/projects/[id]/", params: { id: inquiry.projectId } }')
          icon(name='folder')
          span {{ inquiry.projectTitle }}
  div(class='bleed flex flex-1 flex-col bg-white pt-2')
    div(class='unbleed')
      steps(
        v-if='!isInquiryStatusDeclinedExpiredOrDeleted && breakpoints.sm && (!isNewInquiry || !isInquiryStatusDrafted)'
        class='pr-[46px]'
        :currentActiveId='inquiry.status'
        :data-test-inquiry-status='inquiry.status'
      )
        step#drafted {{ $t('drafted') }}
        step#inquired {{ $t('inquired') }}
        step#processing {{ $t('processing') }}
        step#offered {{ $t('offered') }}
        step#accepted(:completedIfActive='true') {{ $t('accepted') }}

    div(class='flex flex-1 flex-col') 
      div(class='flex flex-1 flex-col') 
        inquiry-product-list(
          class='flex-1'
          :items='inquiry.items'
          :inquiryStatus='inquiry.status'
          :inquiryId='inquiry.id'
          :projectId='inquiry.projectId'
          :allowEditProducts='isInquiryStatusDrafted'
          :showVerifiedState='!isInquiryStatusDrafted'
        )
  div(class='mt-6 flex flex-col-reverse gap-8 rounded-t-lg bg-grey-50 pt-6 sm:flex-row')
    div(class='ml-4 flex-[1.5]')
      div(class='rounded-lg border-[1px] border-solid border-grey-100 p-3')
        inquiry-meta-table(
          v-if='inquiry'
          :showEditButton='inquiry.status === "drafted"'
          :isInquiryStatusAfterOffered='isInquiryStatusAfterOffered'
          :inquiry='inquiry'
          @openDeliverySetupForm='openDeliverySetupForm'
        )
        notification(v-if='!inquiry.deliverySetup' icon='info' :message='$t("Rechnungs- und Lieferinformationen noch nicht angegeben.")' class='mb-2')
    div(class='mr-[46px] min-w-0 flex-1')
      custom-table(class='mb-2 !w-full' style='--td-padding-t: 7px; --td-padding-b: 7px; --tr-bg: transparent; --tr-border-color: var(--grey-100)') 
        tbody(data-target='rowGroupInquiryPrices')
          // IF INQUIRY OFFERED
          template(v-if='isInquiryStatusAfterOffered')
            tr
              td(class='!border-t-0') {{ $t('RRP sum net') }}:
              td(class='!border-t-0 text-right') {{ inquiry.totalRRPNetString }}
            tr
              td
                span(v-html='$t(isInquiryStatusAfterOffered ? "Project discount" : "Project discount (without external products)")')
                span(v-if='hasReachedDiscountLimit') &nbsp;({{ inquiry.discountPercent }})
                span :
                tooltip-button(:text='$t("inquiry.discount_tooltip")' tiny class='ml-2')
              td(v-if='hasReachedDiscountLimit' class='text-right') {{ inquiry.discountAbsolute }}
              td(v-else class='text-right') -
            tr
              td(class='font-normal') {{ $t('Subtotal') }}:
              td(class='text-right font-normal') {{ inquiry.totalDiscountedPriceNetString }}
            tr
              td
                span {{ $t('Delivery Costs') }}
                span(v-if='inquiry.deliveryOption && !inquiry.deliveryOption.name.includes("please select")') !{ ' ' } ({{ $t(inquiry.deliveryOption.name) }})
                span :
              td(class='text-right') {{ inquiry.deliveryCostsString || '-' }}
            tr(v-if='isInquiryStatusOffered && inquiry.deliveryOption && inquiry.deliveryOption.name === "to be discussed" && !inquiry.deliveryCostsString')
              td(colspan='2')
                notification(type='warning' icon='info' :message='$t("We will get back to you soon due to delivery details!")') 
            tr
              td(class='font-bold') {{ $t(isInquiryStatusAfterOffered ? 'Project price' : 'Provisionally project price') }}:
              td(class='text-right !text-xl font-bold') {{ inquiry.totalNetString }}

            tr
              td(class='')
                | {{ $t('Total gross incl. 19% vat ({0})', '', [inquiry.vatAmountString]) }}
              td(class='text-right') {{ inquiry.totalGrossString }}

            //- tr
            //-   td(class='font-bold') {{$t(isInquiryStatusAfterOffered ? 'Project price' : 'Provisionally project price')}}:
            //-   td(class='font-bold text-right !text-xl') {{ inquiry.totalNetString }}
            //- tr
            //-   td {{$t('19% vat')}}:
            //-   td(class='text-right') {{ inquiry.vatAmountString }}

          // WHEN NOT OFFERED
          template(v-else)
            tr
              td(class='!border-t-0 font-bold') {{ $t('RRP sum net') }}:
              td(class='!border-t-0 !py-0 text-right !text-xl font-bold') {{ inquiry.totalRRPNetString }}
            tr
              td {{ $t('Total gross') }}:
              td(class='text-right') {{ inquiry.totalRRPGrossString }}
            tr
              td {{ $t('19% vat') }}:
              td(class='text-right') {{ inquiry.rrpVatAmountString }}
      notification(v-if='isInquiryStatusDrafted' icon='info' :message='$t("inquiry.draft_pricing_message")' class='mb-2')

      div(v-if='inquiry.declineReason && isInquiryStatusDeclinedExpiredOrDeleted' class='my-8')
        label(class='font-bold') {{ $t('Decline Reason') }}:
        p(class='mt-0') {{ inquiry.declineReason }}

  sticky-container(v-if='!inquiry.deleted' position='bottom' class='mb-4 rounded-b-lg bg-grey-50 px-3 py-4 sm:pr-[46px] md:py-6' :enabled='!loading && inquiry.status !== "accepted"')
    // IF INQUIRY NOT EXPIRED
    template(v-if='!isInquiryStatusExpired')
      // IF NOT OFFERED
      action-bar(v-if='!isInquiryStatusAfterOffered')
        btn(v-if='isNewInquiry' medium tertiary data-test-btn='abort' @click='$emit("close")') {{ $t('Abort') }}
        btn(v-else medium tertiary data-test-btn='close' @click='$emit("close")') {{ $t('close') }}
        btn(v-if='inquiry.status === "drafted"' icon='arrow-right' icon-right large data-test-btn='Request' @click='request(inquiryId)') {{ $t('Request') }}
        btn(v-else :disabled='inquiry.status === "processing"' danger large icon='x' data-test-btn='Revoke' @click='revoke(inquiryId)') {{ $t('Revoke') }}

      // IF INQUIRY OFFERED
      action-bar(v-else-if='isInquiryStatusOffered')
        btn(medium tertiary data-test-btn='close' @click='$emit("close")') {{ $t('close') }}
        btn(secondary icon='x' large data-test-btn='DeclineOffer' @click='declineOffer(inquiry.id)') {{ $t('Decline Offer') }}
        btn(large secondary icon='rotate-ccw' data-test-btn='ChangeRequest' @click='changeRequest(inquiry.id)') {{ $t('Change Request') }}
        div(class='divider-v')
        tooltip-button(small class='ml-2' :text='$t("Durch die <b>Annahme</b> des Angebots kommt ein verbindlicher Kaufvertrag zustande.")')
        btn(icon='check' large :loading='loading' data-test-btn='AcceptOffer' @click='acceptInquiry(inquiry.id)') {{ $t('Accept Offer') }}

      // IF INQUIRY ACCEPTED
      action-bar(v-else-if='inquiry.status === "accepted"')
        btn(medium icon='printer' secondary @click='actions.showPrintPreview(inquiryId)') {{ $t('Print Preview') }}

    // IF INQUIRY IS EXPIRED
    template(v-else)
      notification(type='warning' class='mb-4')
        | {{ $t('warning.inquiryExpired') }}
      btn(block data-test='btn-re-request-inquiry' medium secondary icon='arrow-right' icon-right @click='requestWhenExpired')
        | {{ $t('Re-Request Inquiry') }}
</template>

<script setup lang="ts">
import type { IFormResponseError, IFormResponseInfo } from '@/components/GenericForm/types'
import { useCustomBreakpoints, useOnSuccess, useOnError } from '@/composables/'
import { isInquiryDeletable, useInquiry, useInquiryActions } from '@/pages/inquiries/composables'
import type { IInquiry } from '@/pages/inquiries/types'
import { useConfirm } from '@/plugins/Confirm'
import { useModals } from '@/plugins/Modals'
import { useTranslation } from '@/plugins/translation'
import { sleep } from 'radash'
import { computed, defineEmits, defineProps, toRef } from 'vue'

const props = defineProps({
  inquiryId: { type: String, required: true },
  projectId: String,
  isNewInquiry: Boolean,
  editable: { type: Boolean, default: true },
})
const emit = defineEmits(['close'])
const { $t } = useTranslation()
const $modals = useModals()

const {
  loading,
  inquiry,
  items,
  store,
  isInquiryStatusDrafted,
  isInquiryStatusExpired,
  isInquiryStatusAfterOffered,
  load,
  isInquiryStatusOffered,
  setInquiry,
} = useInquiry(toRef(props, 'inquiryId'))

const openDeliverySetupForm = () =>
  $modals.open('form-inquiry-delivery-setup', {
    inquiryId: inquiry.value!.id,
    customerId: inquiry.value!.createdByCompany.companyId,
  })

const { confirm, info, danger, prompt, success } = useConfirm()

// @TODO: remove this as soon as the 502 Error is permanently solved
useOnError(['Revoke', 'AcceptOffer', 'Request', 'Delete'], (response) => {
  if (response.error.response?.status === 502) {
    setTimeout(() => load(), 1000)
  }
})
useOnSuccess(
  [
    'AddOrUpdateItem',
    'UpdateTitle',
    'UpdateInquiry',
    'UpdateProductOrder',
    'AddProductsToInquiry',
    'SetSupplierForProduct',
  ],
  (r: IFormResponseInfo) => {
    if (r.data) {
      setInquiry(r.data.inquiry || r.data) // @TODO: make this more declarative
    } else {
      load()
    }
  },
)
useOnSuccess(['AddOrEditPconProduct'], () => load())
useOnSuccess(['SaveDeliverySetup'], (responseInfo: IFormResponseInfo<IInquiry>) => setInquiry(responseInfo.data))

useOnSuccess(['Accept'], () =>
  success({
    title: $t('Hurra!'),
    content: $t(
      'Wir freuen uns auf Dein Projekt & und die gemeinsame Zusammenarbeit. Wir melden uns in Kürze bei Dir!',
    ),
  }),
)

const hasDeliverySetup = computed(() => inquiry.value?.deliverySetup)

const showRequestSuccessMessage = () =>
  success({
    icon: 'custom-check-circle',
    title: $t('Thank you for requesting the inquiry.'),
    content: $t('We will get back to you as soon as possible.'),
  })
useOnSuccess(['RequestWhenExpired'], async (responseInfo: IFormResponseInfo<IInquiry>) => {
  showRequestSuccessMessage()
  await sleep(300)
  $modals.open('form-inquiry', { projectId: inquiry.value!.projectId!, inquiryId: responseInfo.data.id })
})

const actions = useInquiryActions()

const isInquiryStatusDeclinedExpiredOrDeleted = computed(
  () =>
    inquiry.value?.deleted ||
    isInquiryStatusExpired.value ||
    ['declined', 'declinedWithChangeRequest', 'rejectedByOperator'].includes(inquiry.value?.status || ''),
)

const breakpoints = useCustomBreakpoints()
const hasReachedDiscountLimit = computed(
  () => inquiry.value?.discountAbsolute !== '0,00 €' || inquiry.value?.totalNet >= 1000,
)

const openAddProductsForm = (projectId: string, inquiryId: string) =>
  $modals.open('form-project-add-products', { projectId, inquiryId })
const openAddExternalProductForm = (projectId: string, inquiryId: string) =>
  $modals.open('form-add-or-edit-external-project-item', { projectId, inquiryId })
const openAddProductsFromProjectForm = (projectId: string, inquiryId: string) =>
  $modals.open('form-inquiry-add-products-from-projects', { projectId, inquiryId })
const openPconCatalog = (projectId: string, inquiryId: string, tag: string = '') =>
  $modals.open('modal-pcon-catalog', { projectId, tag, inquiryId })

const request = (inquiryId: string) =>
  info(
    items.value.length === 0
      ? {
          title: $t('info.missingInquiryItems.title'),
          content: $t('info.missingInquiryItems.content'),
        }
      : !hasDeliverySetup.value
        ? {
            title: $t('info.missingDeliveryInformation.title'),
            content: $t('info.missingDeliveryInformation.content'),
            okText: $t('info.missingDeliveryInformation.okText'),
            onOk: () => openDeliverySetupForm(),
          }
        : {
            title: $t('info.inquiryRequest.title'),
            content: $t('info.inquiryRequest.content'),
            okText: $t('info.inquiryRequest.okText'),
            cancelText: $t('info.inquiryRequest.editDeliveryText'),
            onCancel: () => openDeliverySetupForm(),
            onOk: async () => {
              // @TODO: 502 error workaround
              let showSuccessMessage = true
              try {
                await store.requestInquiry(inquiryId)
              } catch (e) {
                showSuccessMessage = (e as IFormResponseError).error.response?.status === 502
              }

              if (showSuccessMessage) {
                showRequestSuccessMessage()
              }
            },
          },
  )
const requestWhenExpired = () => $modals.open('form-inquiry-request-when-expired', { inquiryId: props.inquiryId })
const copyInquiry = (inquiryId: string) =>
  actions.copyInquiry(inquiryId, async (inq) => {
    await sleep(100)
    $modals.open('form-inquiry', { projectId: inquiry.value!.projectId!, inquiryId: inq.id })
  })
const removeInquiry = (inquiryId: string) =>
  isInquiryDeletable(inquiry.value!.status)
    ? actions.deleteInquiry(inquiryId, () =>
        // close modal after close
        emit('close'),
      )
    : info({
        title: $t('Please abort inquiry request before deleting it.'),
      })

const revoke = (inquiryId: string) =>
  danger({
    title: $t('Do you really want to revoke the inquiry?'),
    onOk: () => store.revokeInquiry(inquiryId),
  })
const declineOffer = (inquiryId: string) =>
  prompt($t('Decline Offer'), $t('Decline Reason'), '', { required: true }, true, {
    content: $t('inquiry.decline_reason_message'),
    onOk: (value) => store.declineInquiry(inquiryId, value),
    size: 'sm',
  })
const changeRequest = (inquiryId: string) =>
  prompt($t('Change Request'), $t('Decline Reason'), '', {}, true, {
    content: $t('inquiry.change_request_message'),
    onOk: async (value) => {
      const newInquiry = await store.requestChangesInquiry(inquiryId, value)
      $modals.open('form-inquiry', { projectId: newInquiry.projectId!, inquiryId: newInquiry.id })
    },
    size: 'sm',
  })
const acceptInquiry = (inquiryId: string) =>
  confirm({
    title: $t('Almost there?'),
    content: $t('Accepting the offer will result in a binding contract. We look forward to your order.'),
    cancelText: $t('Abort'),
    okText: $t('I accept'),
    onOk: () => store.acceptInquiry(inquiryId),
  })
</script>
